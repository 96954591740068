<template>
  <div class="main">
    <el-button @click="create">创建视频</el-button>
    <div class="video_box">
      <div v-for="(item, index) in videoList" :key="index" class="video_item">
        <video
          class="video"
          :poster="item.file_first"
          :id="'video' + index"
          controls="controls"
        >
          <source :src="item.video_url" />
        </video>
        <el-button
          class="delete_btn"
          type="danger"
          size="small"
          @click="deleteItem(item.id)"
          >删除</el-button
        >
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <div>
        <el-form class="form-wrapper padding" ref="addForm" label-width="110px">
          <el-form-item label="视频上传" prop="Video">
            <el-upload
              class="avatar-uploader "
              action="/api/kapin_ee/user/v1/files_upload/"
              :show-file-list="false"
              :on-success="handleVideoSuccess"
              :before-upload="beforeUploadVideo"
            >
              <video v-if="is_video" :src="file_url" class="videoa">
                您的浏览器不支持视频播放
              </video>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="视频播放前图片" prop="img">
            <el-upload
              :limit="1"
              class="avatar-uploader el-upload--text"
              action="/api/kapin_ee/user/v1/files_upload/"
              list-type="picture-card"
              :on-success="handleVideoImgSuccess"
              :before-upload="beforeUploadVideoImg"
              :on-remove="removeImg"
              :file-list="img_list"
            >
              <i class="el-icon-plus "></i>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="quxiao">取 消</el-button>
        <el-button type="primary" @click="create_video">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  poster_list,
  poster_del,
  create_video,
} from '@/api/hr24_api/api_all.js'
export default {
  data() {
    return {
      videoFlag: true,
      videoList: [],
      //显示上传按钮
      videoForm: {
        showVideoPath: '',
      },
      file_id: '',
      file_url: '',
      file_first: '',
      image_url: '',
      image_id: '',
      is_video: false,
      dialogVisible: false,
      img_list: [],
    }
  },

  created() {
    this.list()
  },

  methods: {
    create() {
      for (let i = 0; i < this.videoList.length; i++) {
        document.getElementById('video' + i).src = this.videoList[i].video_url
      }
      this.dialogVisible = true
    },
    quxiao() {
      this.dialogVisible = false
      this.file_id = ''
      this.file_url = ''
      this.image_id = ''
      this.image_url = ''
      this.is_video = false
      this.img_list = []
    },
    list() {
      poster_list().then((res) => {
        if (!res.code) {
          this.videoList = res.data.video_data
          this.quxiao()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    create_video() {
      if (this.file_id == '' || this.image_id == '') {
        this.$message.warning('请上传图片或视频')
        return false
      }

      this.dialogVisible = false
      create_video({
        file_id: this.file_id,
        file_type: 'video',
        file_first: this.image_id,
      }).then((res) => {
        console.log(res)
        if (res.code == 0) {
          this.list()
        }
      })
    },
    // 删除视频
    deleteItem(e) {
      console.log(e)
      let that = this
      poster_del(e).then((res) => {
        if (res.code == 0) {
          this.list()
        }
      })
    },
    beforeUploadVideo(file) {
      console.log(file)
      var fileSize = file.size / 1024 / 1024 < 50
      if (
        [
          'video/mp4',
          'video/ogg',
          'video/flv',
          'video/avi',
          'video/wmv',
          'video/rmvb',
          'video/mov',
        ].indexOf(file.type) == -1
      ) {
        this.$message.warning('请上传正确的视频格式')
        return false
      }
      if (!fileSize) {
        this.$message.warning('视频大小不能超过50MB')
        return false
      }
      this.isShowUploadVideo = false
      return (
        fileSize &&
        [
          'video/mp4',
          'video/ogg',
          'video/flv',
          'video/avi',
          'video/wmv',
          'video/rmvb',
          'video/mov',
        ].indexOf(file.type) != -1
      )
    },
    beforeUploadVideoImg(file) {
      console.log(file)
      var fileSize = file.size / 1024 / 1024 < 2
      if (!fileSize) {
        this.$message.warning('视频大小不能超过2MB')
        return false
      }
      const isImage = file.type.includes('image')
      if (!isImage) {
        this.$message.warning('上传文件类型必须是图片!')
      }
      return fileSize && isImage
    },
    handleVideoImgSuccess(res, file) {
      if (JSON.stringify(res) != '{}') {
        console.log(res)
        this.image_url = res.file_url
        this.image_id = res.file_id
        this.$message({
          message: '上传成功',
          type: 'success',
        })
      }
    },
    removeImg(res) {
      this.image_url = ''
      this.image_id = ''
    },
    //上传成功回调
    handleVideoSuccess(res, file) {
      if (JSON.stringify(res) != '{}') {
        console.log(res)
        this.file_url = res.file_url
        this.file_id = res.file_id
        this.is_video = true
        console.log(this.file_url, this.file_id)
        this.$message({
          message: '上传成功',
          type: 'success',
        })
      }
    },
  },
}
</script>

<style scoped>
.main {
  padding: 20px 0;
}
.video_box {
  width: 100%;
}
.video_item {
  width: 150px;
  height: 240px;
  float: left;
  margin-right: 10px;
}
.video {
  width: 100%;
  height: 200px;
}
.delete_btn {
  width: 100%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border: 1px solid #ccc;
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.videoa {
  height: 150px;
  width: 150px;
  object-fit: fill;
}
</style>
